var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.canIncreaseQuota.can_increase_quota
        ? _c(
            "v-banner",
            {
              staticClass: "mb-5",
              scopedSlots: _vm._u(
                [
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              small: "",
                              color: "primary",
                              outlined: "",
                              loading: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.increaseQuota()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.increaseQuotaButtonLabel))]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                63172778
              ),
            },
            [
              _c(
                "v-avatar",
                { attrs: { slot: "icon", color: "white" }, slot: "icon" },
                [_c("v-icon", { attrs: { color: "info" } }, [_vm._v("info")])],
                1
              ),
              _vm._v(
                " " +
                  _vm._s(
                    `Your storage is filling up, consider removing unnecessary files or upgrading the storage quota to ${_vm.canIncreaseQuota.new_limit}`
                  ) +
                  " "
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.canIncreaseQuota.can_increase_quota &&
      _vm.canIncreaseQuota.reason === "rp_fs_utilization_ratio_high"
        ? _c(
            "v-banner",
            {
              staticClass: "mb-5",
              scopedSlots: _vm._u(
                [
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c("contacts-menu", {
                          attrs: {
                            level: "rp",
                            rpId: _vm.defaultResourcePool.rpid,
                            icon: "mdi-account-circle-outline",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2892939254
              ),
            },
            [
              _c(
                "v-avatar",
                { attrs: { slot: "icon", color: "white" }, slot: "icon" },
                [_c("v-icon", { attrs: { color: "info" } }, [_vm._v("info")])],
                1
              ),
              _vm._v(
                " Your storage in this space is filling up, and so does the total combined storage of its resource pool. Upgrading of your storage quota is disabled until the its resource pool's global storage footprint is reduced. Please reach out to your resource pool manager for guidance. "
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.canIncreaseQuota.can_increase_quota &&
      _vm.canIncreaseQuota.reason === "quota_max_limit_reached"
        ? _c(
            "v-banner",
            { staticClass: "mb-5" },
            [
              _c(
                "v-avatar",
                { attrs: { slot: "icon", color: "white" }, slot: "icon" },
                [_c("v-icon", { attrs: { color: "info" } }, [_vm._v("info")])],
                1
              ),
              _vm._v(
                " You storage is filling up, and the quota has already been upgraded to the maximum level. Please remove unused files or reach out to support for further extension. "
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }